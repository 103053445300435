<template>
  <v-container fluid class="pa-0 ma-0">
  <!-- <v-container fluid :class="$vuetify.breakpoint.xs ? 'pa-0' : 'pa-0'"> -->

    <v-row no-gutters>
      <v-col cols="12">
        <v-toolbar
          dense
          flat
        >
          <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
          <v-toolbar-title>
            <v-icon class="mx-1 mb-1" color="black">mdi-badge-account-horizontal-outline</v-icon>
            프로필
          </v-toolbar-title>
          <!-- <div class="flex-grow-1"></div> -->

          <!-- <v-btn icon @click.stop="viewDl">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon>mdi-heart</v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn> -->
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
      </v-col>

      <!-- 개인정보: -->
      <v-col cols="12">
        <v-row
          no-gutters
          align="center"
          justify="start"
        >
          <v-card
            outlined
            class="mx-4 mt-2 mb-2 py-2"
            width="850"
          >
            <v-card-title>
              <span class="text--primary text-h6">개인정보</span>
            </v-card-title>
            <!-- <v-card-subtitle>
              구글계정 > 개인정보페이지
              <v-btn
                small
                text
                color="primary"
                class="ml-0"
                @click="goUrlDirect('https://myaccount.google.com/personal-info')"
              >
                <v-icon small class="mr-1">mdi-launch</v-icon>
                바로가기
              </v-btn>
            </v-card-subtitle> -->
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="3" class="body-2 grey--text text--darken-1 text-start pa-4">
                  사진
                </v-col>
                <v-col cols="9" class="body-1 text--primary text-left pa-4">
                  <v-row no-gutters class="ma-0 pa-0">
                    <!-- <v-avatar
                      v-if="account.picture"
                      size="74"
                      class="pa-2"
                    >
                      <img :src="account.picture">
                    </v-avatar>
                    <v-avatar
                      v-else
                      size="74"
                      class="pa-2"
                      color="grey"
                    >
                      <v-icon>mdi-account-box</v-icon>
                    </v-avatar> -->
                    <v-avatar
                      class="profile"
                      color="#ffffff"
                      size="200"
                      tile
                    >
                      <v-img
                        v-if="account.picture"
                        :lazy-src="lazyImg"
                        :src="account.picture"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <v-icon v-else size="200">mdi-account-box</v-icon>
                    </v-avatar>
                  </v-row>
                  <v-row no-gutters class="ma-0 pa-0">
                    <div class="body-2 grey--text text--darken-1 text-left pt-2">
                      사진 변경은 구글계정에서 가능하며, 재로그인시 반영되어집니다.
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              small text color="primary"
                              @click="goUrlDirect('https://myaccount.google.com/personal-info')"
                            >
                              <v-icon small class="mr-1">mdi-launch</v-icon>
                              구글계정
                            </v-btn>
                        </template>
                        <span>바로가기</span>
                      </v-tooltip>
                    </div>
                  </v-row>
                </v-col>
                <!-- <v-col class="text-right">
                  <v-avatar
                    v-if="account.picture"
                  >
                    <img :src="account.picture">
                  </v-avatar>
                  <v-avatar
                    v-else
                    size="52"
                    class="pa-2"
                    color="grey"
                  >
                    <v-icon>mdi-account-box</v-icon>
                  </v-avatar>
                </v-col> -->
              </v-row>
              <v-divider></v-divider>
              <!-- !! 이름 -->
              <!-- 원본 -->
              <!-- <v-row no-gutters>
                <v-col cols="3" class="text-body-2 grey--text text-start pa-4">
                  이름
                  <v-tooltip
                    right
                    class="ml-3"
                    color="warning"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        small
                        color="warning"
                        style="cursor:pointer;"
                      >mdi-alert</v-icon>
                    </template>
                    <span class="text-body-2">이름 변경은 모든 등록데이터에 영향을 줍니다.</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="9" class="text-body-1 text--primary text-left pa-4">
                  {{ account.name }}
                  <v-btn
                    small
                    text
                    color="purple darken-2"
                    class="ml-2"
                    @click="dummy"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                    수정
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider> -->
              <!-- 작업본 -->
              <v-row no-gutters>
                <v-col cols="3" class="text-body-2 grey--text text--darken-1 text-start pa-4">
                  이름
                  <v-tooltip
                    right
                    class="ml-3"
                    color="warning"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        small
                        color="warning"
                        style="cursor:pointer;"
                      >mdi-alert</v-icon>
                    </template>
                    <span class="text-body-2">이름 변경은 모든 등록데이터에 영향을 줍니다.</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="9" class="text-body-1 text--primary text-left pa-4">
                  <div v-if="nameEdit">
                    <v-row no-gutters class="ma-0 pa-0">
                      <v-text-field
                        ref="nameRef"
                        v-model.trim="account.name"
                        required
                        v-validate="'required|cantSpc|max:30'"
                        :error-messages="errors.collect('name')"
                        data-vv-name="name"
                        maxlength="30"
                        label=""
                        single-line
                        width="100"
                        class="mt-n4"
                        style="max-width: 100px;height: 20px;"
                      ></v-text-field>
                      <v-btn
                        small
                        text
                        color="primary"
                        class="ml-2"
                        @click="editName"
                      >
                        <v-icon small>mdi-check</v-icon>
                        등록
                      </v-btn>
                      <v-btn small text color="error"
                        @click="cancelEditName"
                      >
                        <v-icon small>mdi-cancel</v-icon>
                        취소
                      </v-btn>
                    </v-row>
                  </div>
                  <div v-else>
                    <v-row no-gutters class="ma-0 pa-0">
                      <div class="float-left">{{ account.name }}</div>
                      <v-btn
                        small
                        text
                        color="purple darken-2"
                        class="ml-16"
                        @click="setEditName"
                      >
                        <v-icon small>mdi-pencil</v-icon>
                        수정
                      </v-btn>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <!-- !! 아이디 -->
              <v-row no-gutters>
                <v-col cols="3" class="text-body-2 grey--text text--darken-1 text-start pa-4">
                  아이디
                </v-col>
                <v-col cols="9" class="text-body-1 text--primary text-left pa-4">
                  <!-- <v-icon class="primary--text mr-2">mdi-gmail</v-icon> -->
                  <span class="text--primary">{{ account.email }}</span>
                </v-col>
              </v-row>
              <!-- <v-divider></v-divider>
              <v-row no-gutters>
                <v-col cols="3" class="text-body-2 grey--text text-start pa-4">
                  비밀번호
                </v-col>
                <v-col cols="9" class="text-body-1 text--primary text-left pa-4">
                  ●●●●●●●●●
                </v-col>
                <v-col cols="9" class="text-body-1 text--primary text-left pa-4">
                  비밀번호는 구글계정 > 개인정보페이지에서 변경할 수 있습니다.
                  <v-btn
                    text
                    color="primary"
                    class="px-1"
                    @click="goGooglePersonalInfo"
                  >
                    바로가기
                  </v-btn>
                </v-col>
              </v-row> -->
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>

      <!-- 사무정보: -->
      <v-col cols="12">
        <v-row
          no-gutters
          align="center"
          justify="start"
        >
          <v-card
            outlined
            class="mx-4 mt-5 mb-2 py-2"
            width="850"
          >
            <v-card-title>
              <span class="text--primary text-h6">인사정보</span>
            </v-card-title>
            <v-card-subtitle>
              해당 정보의 수정은 [인사관리]에서 가능합니다.
            </v-card-subtitle>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="3" class="text-body-2 grey--text text--darken-1 text-start pa-4">
                  사원번호
                </v-col>
                <v-col cols="9" class="text-body-1 text--primary text-left pa-4">
                  {{ account.code }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row no-gutters>
                <v-col cols="3" class="text-body-2 grey--text text--darken-1 text-start pa-4">
                  직위
                </v-col>
                <v-col cols="9" class="text-body-1 text--primary text-left pa-4">
                  {{ account.rank }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row no-gutters>
                <v-col cols="3" class="text-body-2 grey--text text--darken-1 text-start pa-4">
                  자격
                </v-col>
                <v-col cols="9" class="text-body-1 text--primary text-left pa-4">
                  {{ account.position }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row no-gutters>
                <v-col cols="3" class="text-body-2 grey--text text--darken-1 text-start pa-4">
                  구내전화
                </v-col>
                <v-col cols="9" class="text-body-1 text--primary text-left pa-4">
                  {{ account.interphone }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row no-gutters>
                <v-col cols="3" class="text-body-2 grey--text text--darken-1 text-start pa-4">
                  팩스번호
                </v-col>
                <v-col cols="9" class="text-body-1 text--primary text-left pa-4">
                  {{ account.fax }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row no-gutters>
                <v-col cols="3" class="text-body-2 grey--text text--darken-1 text-start pa-4">
                  휴대전화
                </v-col>
                <v-col cols="9" class="text-body-1 text--primary text-left pa-4">
                  {{ account.hp }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row no-gutters>
                <v-col cols="3" class="text-body-2 grey--text text--darken-1 text-start pa-4">
                  허용권환
                </v-col>
                <v-col cols="9" class="text-body-1 text--primary text-left pa-4">
                  {{ account.auths }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row no-gutters>
                <v-col cols="3" class="text-body-2 grey--text text--darken-1 text-start pa-4">
                  업무부서
                </v-col>
                <v-col cols="9" class="text-body-1 text--primary text-left pa-4" v-html="account.teams">
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>

      <!-- 발령내역: -->
      <v-col cols="12">
        <v-row
          no-gutters
          align="center"
          justify="start"
        >
          <v-card
            outlined
            class="mx-4 mt-5 mb-2 py-2"
            width="850"
          >
            <v-card-title>
              <span class="text--primary text-h6">발령내역</span>
              <v-spacer></v-spacer>
              <!-- {{ bal.list.length }} -->
            </v-card-title>
            <!-- <v-divider></v-divider> -->
            <!-- <v-card-subtitle>
              관리자 > 인사관리 > 발령내역 리스트입니다.
            </v-card-subtitle> -->
            <v-card-text>
              <v-card
                elevation="0"
                class="overflow-y-auto"
                max-height="300"
              >
                <v-list two-line class="my-0 py-0">
                  <template v-for="(b, index) in bal.list">
                    <v-list-item
                      :key="b.id"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-body-1"
                        >
                          <v-icon v-show="b.isStar" small color="yellow darken-2">mdi-star</v-icon>
                          {{ strDateFormat(b.date1) }}
                          {{ b.gubun1 ? `[${b.gubun1}]` : '' }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-html="b.dta1"
                          class="text-body-2"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider
                      v-show="bal.list.length > index + 1"
                      :key="b.createdAt"></v-divider>
                  </template>
                </v-list>
              </v-card>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>

      <!-- 휴가|근태: -->
      <v-col cols="12">
        <v-row
          no-gutters
          align="center"
          justify="start"
        >
          <v-card
            outlined
            class="mx-4 mt-5 mb-2 py-2"
            width="850"
          >
            <v-card-title>
              <span class="text--primary text-h6">휴가|근태</span>
              <v-spacer></v-spacer>
              <!-- {{ hu.list.length }} -->
            </v-card-title>
            <!-- <v-divider></v-divider> -->
            <!-- <v-card-subtitle>
              관리자 > 인사관리 > 휴가|근태 리스트입니다.
            </v-card-subtitle> -->
            <v-card-text>
              <v-card
                elevation="0"
                class="overflow-y-auto"
                max-height="300"
              >
                <v-list two-line class="my-0 py-0">
                  <template v-for="(h, index) in hu.list">
                    <v-list-item
                      :key="h.id"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-body-1"
                        >
                          <v-icon v-show="h.isStar" small color="yellow darken-2">mdi-star</v-icon>
                          {{ strDateFormat(h.date1) }}
                          {{ h.gubun1 ? `[${h.gubun1}]` : '' }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-html="h.dta1"
                          class="text-body-2"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider
                      v-show="hu.list.length > index + 1"
                      :key="h.createdAt"></v-divider>
                  </template>
                </v-list>
              </v-card>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>

      <!--사용안함: 바로가기 섹션 -->
      <!-- <v-col cols="12">
        <v-row
          no-gutters
          align="center"
          justify="start"
        >
          <v-card
            outlined
            class="mx-4 mt-5 mb-2 py-2"
            width="850"
          >
            <v-card-title>
              <span class="text--primary text-h6">바로가기</span>
            </v-card-title>
            <v-card-text>
              <v-row no-gutters>
                <v-col class="text-body-2 grey--text text-start pa-4">
                  <v-btn
                    large
                    text
                    @click="goGooglePersonalInfo"
                    class="ma-2 pa-8"
                    color="warning"
                  >
                    <span class="mr-1">구글계정</span>
                    <v-icon large right>
                      mdi-google
                    </v-icon>
                  </v-btn>
                  <v-btn
                    large
                    text
                    @click="goGmail"
                    class="ma-2 pa-8"
                    color="primary"
                  >
                    <span class="mr-1">G메일</span>
                    <v-icon large right>
                      mdi-gmail
                    </v-icon>
                  </v-btn>
                  <v-btn
                    드라이브
                    text
                    @click="goGdrive"
                    class="ma-4 pa-8"
                    color="success"
                  >
                    <span class="mr-1">드라이브</span>
                    <v-icon large>
                      mdi-google-drive
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col> -->

      <!-- 서비스해지: -->
      <v-col cols="12">
        <v-row
          no-gutters
          align="center"
          justify="start"
        >
          <v-card
            outlined
            class="mx-4 mt-5 mb-2 py-2"
            width="850"
          >
            <v-card-title>
              <span class="text--primary text-h6">회원 탈퇴</span>
            </v-card-title>
            <v-card-subtitle>
              로워크 서비스를 탈퇴해도 구글계정에는 영향이 없습니다<div class=""></div>
            </v-card-subtitle>
            <v-card-text>
              <v-row no-gutters>
                <v-col class="text-body-1 text--primary text-left pa-4">
                  <div>회원탈퇴는 공유해제가 선행된 이후에 가능합니다.</div>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="text-left">
                  <!-- <div class="mt-2 grey--text text--darken-1 text-body-2">
                    <v-icon small>mdi-information-outline</v-icon> 궁금하신 사항은 info@lawork.co.kr 혹은 02-521-7505로 연락주시기 바랍니다.
                  </div> -->
                  <v-alert
                    text
                    dense
                    type="info"
                    class="text-body-2"
                  >
                    궁금하신 사항은 고객센터로 문의하십시오.
                  </v-alert>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-row
          no-gutters
          align="center"
          justify="start"
        >
          <br><br>
        </v-row>
      </v-col>

    </v-row>

    <!-- !! overlay -->
    <v-overlay :value="overlay" opacity="0.25">
      <v-progress-circular indeterminate size="70" color="primary"></v-progress-circular>
    </v-overlay>

  </v-container>
</template>

<script>
// @: vee-validate
import ko from 'vee-validate/dist/locale/ko'

// @: sleep
import sleep from '@/lib/sleep'

// @: filters
import strDateFormat from '@/filters/strDateFormat'

export default {
  components: {},

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 계정정보
    account: {
      name: '',
      picture: '',
      email: '',
      code: '',
      rank: '',
      position: '',
      interphone: '',
      fax: '',
      hp: '',
      auths: '',
      teams: ''
    },
    // 구분: 발령내역
    bal: {
      totalItems: 0,
      list: []
    },
    // 구분: 휴가|근태
    hu: {
      totalItems: 0,
      list: []
    },
    // 구분: 오버레이용 변수
    overlay: false,
    // 구분: 이름수정에 사용하는 변수들
    nameEdit: false, // 이름 수정 플래그 변수
    newName: '', // 새이름을 잠시 저장하고 백앤드에 전송할 때 사용
    oldName: '', // 변경전 이름
    // 구분: 이름을 변경할때 사용하는 vee-validate
    dictionary: {
      messages: ko.messages,
      attributes: {
        name: '이름'
      }
    },
    // 구분: 이미지 로딩시 기다릴때 이미지
    lazyImg: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDI0IDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjx0aXRsZS8+PGNpcmNsZSBjeD0iMTIiIGN5PSI4IiBmaWxsPSIjNDY0NjQ2IiByPSI0Ii8+PHBhdGggZD0iTTIwLDE5djFhMSwxLDAsMCwxLTEsMUg1YTEsMSwwLDAsMS0xLTFWMTlhNiw2LDAsMCwxLDYtNmg0QTYsNiwwLDAsMSwyMCwxOVoiIGZpbGw9IiM0NjQ2NDYiLz48L3N2Zz4='
  }),

  computed: {
  },

  created () {
    // 중요: vee-validate 커스텀 룰 적용 - 특수문자 허용안됨
    // !! 특수문자를 거르는 필터. 공백허용(이름 중간에 공백이 들어갈 수 있으므로)
    this.$validator.extend('cantSpc', {
      getMessage: field => '특수문자는 허용되지 않습니다',
      validate: value => {
        const pattern = /[{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]/gi
        return !pattern.test(value)
      }
    })
  },

  mounted () {
    this.$validator.localize('ko', this.dictionary)

    // 중요: 정상적으로 로그인하지 않으면 콘솔에 에러가 나는데.. 이 에러는 오히려 콘솔창에 생기라고 놔둬야 한다!
    // 이미 router.js 에서 로그인하지 않은경우 처리하므로 다시 '/' 로 뺄 필요도 없다..
    if (!this.$store.state.ui.dbcode) {
      // this.redirect('/')
    }

    this.account.picture = ''

    this.getAccountInfo() // 로그인 유저의 계정정보를 패칭
  },

  watch: {
  },

  methods: {
    strDateFormat,
    dummy () {
      try {
        console.log('dummy test')
      } catch (e) {
        this.sbpop(e)
      }
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 계정정보 패칭
    async getAccountInfo () {
      try {
        // !! account 초기화
        this.account.name = ''
        this.account.email = ''
        this.account.picture = ''
        this.account.code = ''
        this.account.rank = ''
        this.account.position = ''
        this.account.interphone = ''
        this.account.fax = ''
        this.account.hp = ''
        this.account.auths = ''
        this.account.teams = ''

        // !! 발령내역 초기화
        this.bal.totalItems = 0
        this.bal.list = []

        // !! 휴가|근태 초기화
        this.hu.totalItems = 0
        this.hu.list = []

        // !!주의: 현재 유저의 아이디로 admin/staff/profileById/:id 로 보내면 오류
        //        위 컨트롤러는 staffs 테이블의 아이디를 기반으로 찾기 때문에 맞지 않는다!
        // const accountId = this.$store.state.ui.id // 로그인 유저의 아이디(로컬스토리지에 저장된 아이디)
        // const { data } = await this.$axios.get(`admin/staff/profileById/${accountId}`)
        // !! 이메일로 찾는것으로 변경함
        const { data } = await this.$axios.get(`admin/staff/profileByEmail/${this.$store.state.ui.email}`)
        if (!data.success) throw new Error(`데이터를 가져오지 못했습니다.: ${data.message}`)

        // 참고: 조직원 정보 + 발령내역 + 휴가|근태
        // admin/viewStaffInfo.vue getStaffInfo() 와 동일
        const { staffInfo, bal, hu } = data

        this.bal = bal // !! 발령내역
        this.hu = hu // !! 휴가|근태
        // console.log(this.bal)

        // 구분: 개인정보
        this.account.name = staffInfo.name
        this.account.email = staffInfo.email
        this.account.picture = staffInfo.picture

        // 구분: 사무정보
        this.account.code = staffInfo.code
        this.account.rank = staffInfo.rank
        this.account.position = staffInfo.position
        this.account.interphone = staffInfo.interphone
        this.account.fax = staffInfo.fax
        this.account.hp = staffInfo.hp

        // 구분: 허용권한
        let authArr = []
        if (staffInfo.auth1 === 1) {
          authArr.push('계약+수금')
        }
        if (staffInfo.auth2 === 1) {
          authArr.push('비용')
        }
        if (staffInfo.auth3 === 1) {
          authArr.push('타임시트')
        }
        if (authArr.length > 0) {
          this.account.auths = authArr.join('|')
        }

        // 구분: 업무부서
        // this.account.teams = ''
        if (staffInfo.teams.length > 0) {
          let teamView = []
          staffInfo.teams.forEach(team => {
            // !![2021.7.27] 회의실관리팀은 제외한다. 차후 다시 살린다.
            if (team.name !== '회의실관리팀') {
              const colors = team.color.split(' ')
              const color2 = colors.length === 2 ? ` text--${colors[1]}` : ''
              const txtColor = `${colors[0]}--text${color2}`
              teamView.push(`<span class="${txtColor}">${team.name}</span>`)
            }
          })
          this.account.teams = teamView.join('|')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 이름 수정 함수
    async editName () {
      try {
        if (!this.account.name) {
          this.$refs.nameRef.focus()
          throw new Error('이름을 입력하세요.')
        }

        this.newName = this.account.name
        if (this.newName === this.oldName) {
          this.$refs.nameRef.focus()
          throw new Error('다른 이름을 입력하세요.')
        }

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `계속 진행하시겠습니까?<br>이름변경은 모든 등록데이터에 영향을 줍니다.`
        if (await pop.open('이름변경', msg, { color: 'info', width: 400 })) {
          this.overlay = true

          // !! DB 처리: 새 이름을 저장한다
          const form = {
            oldName: this.oldName,
            newName: this.newName
          }
          const { data } = await this.$axios.post(`lawork/member/editUserName`, form)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          // !! 이름을 바꾸는데 썼던 변수는 초기화
          this.newName = ''
          this.oldName = ''
          // !!
          this.nameEdit = false
          //
          sleep(550).then(() => { this.overlay = false }) // shut off overlay
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 이름 수정 초기 세팅 함수
    async setEditName () {
      try {
        this.nameEdit = true
        this.oldName = this.account.name // !! 현재 이름을 저장해야 한다.
        this.newName = '' // 이넘은 초기화
        this.overlay = false

        this.$nextTick(() => {
          this.$refs.nameRef.focus()
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 이름 수정 취소 함수
    async cancelEditName () {
      try {
        this.$validator.reset() // vee-validator 초기화
        this.account.name = this.oldName // 기존 이름 복구
        // 이름을 바꾸는데 썼던 변수는 초기화
        this.newName = ''
        this.oldName = ''
        // !! v-text-field 를 마지막에 안보이게 해야 에러가 안난다.
        this.$nextTick(() => {
          this.nameEdit = false
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 새로 탭을 열고 인자로 전달받은 링크로 이동시키는 함수
    async goUrlDirect (gUrl) {
      try {
        const link = document.createElement('a')
        link.href = gUrl
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
